import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from './services/language.service';
import { MenuController } from '@ionic/angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';
// For local dev, @environments/google-api is in cicd-tootlkit at conf/local/hippy-public-application/google-api.ts
import { GoogleMapsApiUrl } from '@environments/google-api';
import { GoogleApiService } from './services/google-api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public appPages = [
    { title: 'Enregistrer une ceinture', url: '/register-device', icon: 'add-outline' },
    { title: 'Mes ceintures', url: '/devices', icon: 'list-outline' },
    { title: 'Historique', url: '/history', icon: 'time-outline' },
    { title: 'SAV', url: '/sav', icon: 'construct-outline' },
    { title: 'Aide', url: '/help-main', icon: 'information-circle-outline' },
  ];

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private menuController: MenuController,
    private zone: NgZone,
    private router: Router,
    private googleApiService: GoogleApiService
  ) {
    this.initializeApp();
    this.translate.setDefaultLang('en');
    this.languageService.getLanguage();
  }

  ngOnInit() {
    this.menuController.enable(false);
    this.loadGoogleMapsApi();
    this.addKeyboardListeners();
  }

  ngOnDestroy() {
    this.removeKeyboardListeners();
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split(".com").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  private addKeyboardListeners() {
    window.addEventListener('keyboardWillShow', this.onKeyboardShow);
    window.addEventListener('keyboardWillHide', this.onKeyboardHide);
  }

  private removeKeyboardListeners() {
    window.removeEventListener('keyboardWillShow', this.onKeyboardShow);
    window.removeEventListener('keyboardWillHide', this.onKeyboardHide);
  }

  private onKeyboardShow = (event: any) => {
    setTimeout(() => {
      document.activeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      document.body.style.paddingBottom = `${event.keyboardHeight}px`;
    }, 300); // Adjust timing based on your needs
  }

  private onKeyboardHide = () => {
    document.body.style.paddingBottom = '0px';
  }

  private loadGoogleMapsApi(): void {
    const script = document.createElement('script');
    script.src = GoogleMapsApiUrl;
    script.defer = true;
    script.onload = () => {
      this.googleApiService.setMapsLoaded(true);
    };
    document.head.appendChild(script);
  }
}