import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserAppLoadNavigationService } from '@app/services/user-app-load-navigation.service';
import { AlertService } from '@app/services/alert.service';
import { countPendingContacts } from '@app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private userAppLoadNavigation: UserAppLoadNavigationService,
    private translateService: TranslateService,
    private alertCtrl: AlertController,
    private alertService: AlertService,
  ) {}

  registerUser(credentials: {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
  }): Observable<any> {
    var dataRegister = {
      email: credentials.email,
      phone: credentials.phone,
      firstName: credentials.firstName,
      lastName: credentials.lastName,
    };

    return this.http.post(`${environment.apiUrl}/user/register`, dataRegister);
  }

  getUsersList(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/user/list/`);
  }

  getUserInfo(userId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/user/${userId}/`);
  }

  getUserOwnedDevices(userId): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/device/ownerDevices/${userId}/`
    );
  }

  doesUserHasDevice(userId): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/device/hasDevice/${userId}/`,
      {}
    );
  }

  doesUserIsContact(userId): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/patientContact/isContact/${userId}/`,
      {}
    );
  }

  HaveIDevice(): Observable<any> {
    return this.http.post(`${environment.apiUrl}/device/hasDevice/`, {});
  }

  AmIContact(): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/patientContact/isContact/`,
      {}
    );
  }

  canIAccesThisUserData(userId): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/patientContact/canAccessThisUserData/${userId}/`,
      {}
    );
  }

  doesHaveIDevice(userId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/device/hasDevice/`, {});
  }

  getMyOwnedDevices(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/device/myDevices/`);
  }

  getUserDevice(userId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/device/patient/${userId}/`);
  }

  getUserContacts(userId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/patientContact/${userId}/`);
  }

  getUserPatients(userId): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/patientContact/contact/${userId}/`
    );
  }

  getUserPatientInfos(userId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/patient/${userId}/`);
  }

  deleteUser(userId): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/user/${userId}/`);
  }

  modifyUserInfos(
    credentials: {
      email;
      country_code;
      phone;
      firstName;
      lastName;
      address;
    },
    userId
  ): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/user/${userId}/`,
      credentials
    );
  }

  treatContactInvitation(invitationState, patientId, contactId): Observable<any> {

    let data = {
      invitationState: invitationState,
      contactId: contactId,
    }

    return this.http.patch(
      `${environment.apiUrl}/patientContact/${patientId}/`,
      data
    );
  }

  registerPatientContact(patientId, contactId, relative): Observable<any> {
    var dataRegister = {
      patientId: patientId,
      contactId: contactId,
      relative: relative,
    };

    return this.http.post(
      `${environment.apiUrl}/patientContact/${patientId}/`,
      dataRegister
    );
  }

  inviteContact(patientId, dataUser): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/patientContact/addContact/${patientId}/`,
      dataUser
    );
  }

  inviteExistingUserContact(patientId, contactId, relative): Observable<any> {
    var dataRegister = {
      patientId: patientId,
      contactId: contactId,
      relative: relative,
      invitationState: "PENDING"
    };
    return this.http.post(
      `${environment.apiUrl}/patientContact/${patientId}/`,
      dataRegister
    );
  }

  deletePatientContact(patientId, contactId): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}/patientContact/delete/${patientId}/${contactId}/`
    );
  }

  checkPhone(country_code: string, phone: string): Observable<any> {
    var data = {
      country_code: country_code,
      phone: phone,
    };
    return this.http.post(
      `${environment.apiUrl}/user/checkPhone/`,
      data
    );
  }

  changePhone(
    credentials: { country_code, phone },
  ): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/user/changePhone/`, credentials,
    );
  }

  createUser(patient, owner, confirmationCode?): Observable<any> {
    const data = {
      patient,
      owner,
      confirmationCode
    }
    return this.http.post(`${environment.apiUrl}/user/createUser/`, data);
  }

  createFullUser(patient, owner, contacts, activationCode): Observable<any> {
    return this.http.post(`${environment.apiUrl}/user/createFullUser/${activationCode}/`, {
      patient,
      owner,
      contacts,
    });
  }
  
  doesUserHasDevicesOrPatients(userId, userRole, params?) {
    let pendingCount = 0;
    this.getMyOwnedDevices().subscribe(
      (devices) => {
        this.getUserPatients(userId).subscribe(
          (userHasPatients) => {
            pendingCount = countPendingContacts(userHasPatients);
            this.userAppLoadNavigation.redirectUserOnAppLoad(pendingCount, userId, devices, userHasPatients, userRole, params);
          },
          (err) => {
            this.alertService.presentAlert(
              this.translateService.instant("error"),
              '',
              this.translateService.instant(`backend.${err.error.detail}`)
            );
          }
        );
      },
      (err) => {
        this.alertService.presentAlert(
          this.translateService.instant("error"),
          '',
          this.translateService.instant(`backend.${err.error.detail}`)
        );
      }
    );
  }
}
