import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserAppLoadNavigationService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  redirectUserOnAppLoad(pendingCount, userId, userDevices, hasContactList, role_type, params?) {
    if (params?.onboarding) {
      switch (params?.onboarding) {
        case "login":
          this.router.navigate([`/activate/${params.activationCode}/select-profile`]);
          break;
        case "contactsList":
          this.router.navigate([`${userId}/contacts-list`]);
          break;
        case "addContact":
          this.router.navigate([`${userId}/contact-add`]);
        default:
      }
      return
    }
    if (role_type == "ADMIN") {
      this.router.navigateByUrl(`${userId}/home-bo`, {replaceUrl: true});
    } else {
      this.router.navigate([userId + '/user-devices/' + userId]);
    }
  }
}