export const isDifferentUser = (userId: number, loggedInUserId: number) => {
    if (userId !== loggedInUserId) {
        return true;
    }
    return false;
};

export const countPendingContacts = (patientContacts) => {
    var count = 0;
    patientContacts.forEach((patientContact) => {
      if (patientContact.invitationState == 'PENDING') {
        count++;
      }
    });
    return count;
  }