import { Injectable } from '@angular/core';
import { ActivatedRoute, CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentification.service';
import { filter, map, take } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserAppLoadNavigationService } from '@app/services/user-app-load-navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AutoLoginGuard implements CanLoad {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private userAppLoadNavigation: UserAppLoadNavigationService,
    private route: ActivatedRoute,
  ) {}

  goUserDevices = false;
  userHasDevices;
  userHasPatients;
  params;

  canLoad(): Observable<boolean> {
    this.route.queryParams.pipe(
      filter(params => params.onboarding))
      .subscribe(params => {
        this.params = params
      }
    );
    return this.authService.isAuthenticated.pipe(
      filter((val) => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map((isAuthenticated) => {
        if (isAuthenticated) {
          this.userService.doesUserHasDevicesOrPatients(localStorage.getItem('user_id'), localStorage.getItem('role'), this.params);
          return false; //For comprehension but redirected anyway
        } else {
          // Simply allow access to the login
          return true;
        }
      })
    );
  }
}
